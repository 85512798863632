.divot {
    position: absolute;
    top: calc(-2rem + 1px);
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 2rem;
    justify-content: center;

    &::before,
    &::after {
        position: absolute;
        width: calc(50% - 1.5rem);
        height: 2rem;
        content: '';
    }

    &::before {
        left: 0;
    }

    &::after {
        right: 0;
    }

    &.blue {
        color: var(--colors-blue);

        &::before,
        &::after {
            background-color: var(--colors-blue);
        }
    }

    &.blueMedium {
        color: var(--colors-blue);

        &::before,
        &::after {
            background-color: var(--colors-blue);
        }
    }

    &.blueDark {
        color: var(--colors-blueDark);

        &::before,
        &::after {
            background-color: var(--colors-blueDark);
        }
    }

    &.cream {
        color: var(--colors-cream);

        &::before,
        &::after {
            background-color: var(--colors-cream);
        }
    }

    &.creamDark {
        color: var(--colors-cream);

        &::before,
        &::after {
            background-color: var(--colors-cream);
        }
    }
}

@import 'styles/breakpoints';
@import 'styles/mixins';

.calloutWrapperFlex {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    &.banner {
        height: var(--calloutWrapper-banner-height, auto);
        max-width: var(--block-max-width);

        @include rounded-corners($overflow: hidden, $radius: 2rem 2rem 0 0);

        &.roundedCorners {
            @include rounded-corners($overflow: hidden);
        }

        &.makers-reserve {
            border-radius: 0;
        }
    }
}

.divot {
    position: relative;
    top: 0;
    overflow: unset;
    height: 1.9rem;
}

.calloutWrapper {
    position: relative;
    width: 100%;
    height: 100%;

    .card & {
        height: 100%;
    }
}

.callout {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: var(--callout-min-height, 100vw);
    padding-bottom: var(--callout-padding-bottom, 0);

    .recommended & {
        min-height: 0;
    }

    .masthead & {
        min-height: 0;
    }

    .hero & {
        min-height: 0;
    }

    .bannerSimple & {
        min-height: var(--callout-bannerSimple-min-height, 59vw);
        display: flex;
        align-items: center;
    }

    .card & {
        height: 100%;
    }

    .banner & {
        height: var(--callout-banner-height, auto);
        display: flex;
        align-items: center;
    }

    .hasNotch & {
        min-height: 0;
    }

    .bannerSimple.hasNotch & {
        min-height: var(--callout-min-height-hasNotch, calc(59vw - 2rem));
    }
}

.inner {
    position: var(--inner-position, absolute);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--block-padding-end);
    text-align: center;

    .masthead & {
        position: relative;
        padding: 6.5rem var(--block-padding-end) 8rem;
    }

    .hero & {
        position: relative;
        padding: var(--block-padding-end) 0;
    }

    .banner & {
        position: relative;
        flex-direction: var(--inner-banner-flex-direction, column);
    }

    .banner.withCopy & {
        position: relative;
        justify-content: var(--inner-banner-withCopy-justify-content, center);
    }

    .hasNotch & {
        padding: var(--inner-padding-hasNotch, 5.2rem 3.6rem 7.2rem);
    }

    .bannerSimple & {
        padding: var(--bannerSimple-padding, var(--block-padding-end));
    }

    .bannerSimple.hasNotch & {
        padding: var(
            --bannerSimple-padding,
            2.8rem var(--block-padding-end) var(--block-padding-end)
        );
    }
}

.eyebrow {
    max-width: 37.5rem;

    .banner & {
        max-width: 64rem;
    }

    .banner.withCopy & {
        max-width: 37.5rem;
    }
}

.copy {
    display: inline-block;
    padding: 2rem 0 0;
    max-width: 48.6rem;

    .banner & {
        padding: var(--copy-banner-padding, 2rem 0 0 0);
        text-align: var(--copy-banner-text-align, center);
    }
}

.imageContainer {
    position: var(--imageContainer-position, relative);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
        width: 100%;
        max-width: none;
    }

    .banner & {
        display: none;
    }
}

.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .withCopy & {
        width: var(--section-withCopy-width, auto);

        &:last-child {
            align-items: var(--section-withCopy-last-child-align-items, center);
        }
    }

    .bannerSimple & {
        max-width: 51.5rem;
    }
}

.simpleEyebrow {
    text-transform: uppercase;
    margin: 0 0 1rem;
}

.buttonComponentSlot {
    margin-top: 3rem;
}

@include medium {
    .calloutWrapperFlex {
        --callout-min-height-hasNotch: 0;

        &.banner {
            --callout-min-height: 0;
            --inner-position: relative;
        }

        --callout-bannerSimple-min-height: 0;
        --callout-padding-bottom: 0;

        &.card {
            --callout-padding-bottom: 0;
        }

        --imageContainer-position: absolute;
        --inner-padding-hasNotch: 7.8rem 6rem 9rem;
        --bannerSimple-padding: 8rem 9.6rem 8rem 9.6rem;

        &.hasNotch {
            --bannerSimple-padding: 6rem 9.6rem 8rem 9.6rem;
        }

        &.bannerSimple {
            --callout-min-height-hasNotch: 20.2rem;
        }
    }
}

@include large {
    .calloutWrapperFlex {
        --callout-min-height: 0;
        --inner-banner-flex-direction: row;
        --inner-banner-withCopy-justify-content: space-between;
        --copy-banner-padding: 0 0 0 2rem;
        --copy-banner-text-align: left;
        --section-withCopy-width: 50%;
        --section-withCopy-last-child-align-items: flex-start;
    }

    .inner {
        .masthead & {
            padding: 8.8rem var(--block-padding-end) 9rem;
        }

        .recommended & {
            padding: var(--block-padding-end) 2.4;
        }

        .hero & {
            padding: 9.6rem 0;
        }
    }

    .eyebrow {
        .recommended & {
            max-width: 27rem;
        }

        .banner & {
            max-width: 78rem;
        }

        .banner.makers-reserve & {
            max-width: 58rem;
        }

        .hero & {
            max-width: 85rem;
        }
    }

    .buttonComponentSlot {
        margin-top: 4rem;
    }
}

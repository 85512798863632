@import 'styles/breakpoints';
@import 'styles/animations';

.imageOffsetContent {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--colors-blue);

    &.whiteBackground {
        background-color: var(--colors-white);
    }
}

.eyebrow {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: var(--block-padding-end) 0;
    color: var(--colors-blue);
}

.columns {
    position: relative;
    z-index: 2;
    display: flex;
    width: 100%;
    max-width: var(--block-max-width);
    flex-direction: var(--columns-flex-direction, column-reverse);
    padding: var(--columns-padding, 0);
}

.column {
    display: flex;
    width: var(--column-width, 100%);
    flex-direction: column;
    align-items: flex-start;
    justify-content: var(--column-justify-content, flex-start);

    .hasTestimonial & {
        align-items: center;

        --column-justify-content: center;
    }

    .hasTestimonialWithoutImage & {
        --column-justify-content: center;
    }
}

.columnContent {
    padding: var(--columnContent-padding, 4.8rem 2.4rem);

    &.noContent {
        padding: var(--columnContent-padding, 2.4rem 2.4rem);
    }
}

.contentParagraph {
    margin-top: 2rem;
}

.columnOffsetContent {
    padding: var(--columnOffsetContent-padding, 0);
    margin: var(--columnOffsetContent-margin, 0);
}

@include large {
    .imageOffsetContent {
        --columns-flex-direction: row;
        --columns-padding: 0 var(--block-padding-side) var(--block-padding-end)
            var(--block-padding-side);
        --column-width: 50%;
        --columnOffsetContent-margin: -35.5% 0 0 0;
        --columnOffsetContent-padding: 0 0 0 2.4rem;

        &.offsetLeft {
            --columns-flex-direction: row-reverse;
            --columnOffsetContent-padding: 0 2.4rem 0 0;
        }

        --columnContent-padding: 10rem 6rem 4.8rem;

        &.hasTestimonial {
            --columnContent-padding: 4.8rem 2.4rem;
        }

        .columnOffsetContent {
            transform: translateY(100px);

            &.isInView {
                transform: translateY(0);
                transition: transform $easeOutQuad 0.5s;
            }
        }
    }
}

@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/mixins';

.root {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 0;
    padding-bottom: 125%;
}

.track {
    display: flex;
    min-width: min-content;
}

.slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    animation: slide-fade-in 0s linear forwards;
    opacity: 0;

    @include spread;

    .isReady & {
        animation: slide-fade-in var(--duration) linear forwards;
    }

    &.initial {
        animation: none;
        opacity: 1;
    }

    &.user {
        animation: slide-fade-in-user 0.6s linear forwards;
    }
}

.bgImage {
    width: 100%;
    height: 100%;

    @include spread;
}

.imageWrapper {
    position: absolute;

    @include spread;
}

.image {
    width: 100%;
    max-width: none;
    height: 100%;
    object-fit: cover;
}

.dots {
    position: absolute;
    z-index: 3;
    right: 0;
    bottom: 0;
    left: auto;
    display: flex;
    width: 100%;
    height: 6rem;
    align-items: center;
    justify-content: center;
}

.dot {
    width: 1rem;
    height: 1rem;
    padding: 0.25rem;
    border: 0.2rem solid var(--colors-white);
    border-radius: 50%;
    margin: 0.4rem;
    background-color: transparent;
    transition: background-color $easeOutCubic $superSlow;

    &.active {
        background-color: var(--colors-white);
    }
}

@keyframes slide-fade-in {
    0% {
        opacity: 0;
        transform: scale(1.2);
    }

    30% {
        opacity: 1;
        transform: scale(1.14);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes slide-fade-in-user {
    0% {
        opacity: 0;
        transform: scale(1.06);
    }

    50% {
        opacity: 1;
        transform: scale(1.03);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@include medium {
    .root {
        padding-bottom: 56.25%;
    }
}

@include large {
    .root {
        height: 90vh;
        max-height: var(--block-max-height);
        padding-bottom: 0;
    }

    .dots {
        right: 10rem;
        height: 11rem;
        justify-content: flex-end;

        &.dotsOnLeft {
            left: 10rem;
            justify-content: flex-start;
        }
    }
}

@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/colors';

.root {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    min-height: var(--root-min-height, none);
    max-height: 50rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--root-padding, 1rem);
    color: var(--colors-blue);

    &.flushTop {
        padding-top: 0;
    }

    &.flushBottom {
        padding-bottom: 0;
    }
}

.inner {
    display: flex;
    width: 100%;
    height: 70vw;
    flex-direction: var(--inner-flex-direction, column);
    justify-content: center;
    gap: 2rem;

    .light &,
    .isCreamHeavy & {
        height: 115vw;
        max-height: 50rem;
    }
}

.col {
    position: relative;
    height: 100%;
    min-height: 60vw;
    flex: 1;
}

.mediaWrapper {
    position: relative;
    width: var(--root-width, 75%);
    max-width: var(--root-max-width, 35rem);
    height: 100%;
    margin: 0 auto;
    cursor: pointer;

    .light &,
    .isCreamHeavy & {
        height: 125%;

        --root-width: 100%;

        max-height: 42.5rem;
    }
}

.nav {
    position: relative;
    bottom: max(-5vw, -3rem);
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.navArrow {
    margin: 1.5rem;
}

.dots {
    position: relative;
    display: flex;
    height: 6rem;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    user-select: none;
}

.dot {
    width: 1rem;
    height: 1rem;
    border: 0.2rem solid var(--colors-blue);
    border-radius: 50%;
    margin: 0.4rem;
    background-color: transparent;
    transition: background-color $easeOutCubic $superSlow;

    &.active {
        background-color: var(--colors-blue);
    }

    .light & {
        border-color: var(--deck-nav-color, var(--colors-cream));

        &.active {
            background-color: var(--deck-nav-color, var(--colors-cream));
        }
    }
}

@include smallOnly {
    .root {
        --root-min-height: 80vw;

        &.light,
        &.isCreamHeavy {
            --root-min-height: 65rem;
        }
    }
}

@include medium {
    .root {
        height: 60vw;

        &.light,
        &.isCreamHeavy {
            height: 80vw;
            max-height: 70rem;
        }
    }

    .inner {
        .light &,
        .isCreamHeavy & {
            height: 100vw;
        }
    }

    .nav {
        bottom: 4rem;

        .light &,
        .isCreamHeavy & {
            bottom: -2rem;
        }
    }

    .mediaWrapper {
        --root-width: 47%;
        --root-max-width: 50rem;

        .light &,
        .isCreamHeavy & {
            --root-max-width: 42.5rem;

            max-height: 46.5rem;
        }
    }
}

@include large {
    .root {
        --root-padding: 5rem 0 7rem;
        --inner-flex-direction: row-reverse;

        height: min(45rem, 50vw);

        &.flushTop {
            padding-top: 0;
        }

        &.flushBottom {
            padding-bottom: 0;
        }

        &.light,
        &.isCreamHeavy {
            height: min(60rem, 60vw);
        }
    }

    .inner {
        align-items: center;
        justify-content: flex-start;
    }

    .col {
        max-width: 44rem;
        min-height: unset;
        max-height: min(40vw, 50rem);
        flex: 1;
    }

    .nav {
        bottom: -5rem;

        .light & {
            bottom: -4rem;
        }
    }

    .mediaWrapper {
        --root-width: 100%;
        --root-max-width: 44rem;

        .light &,
        .isCreamHeavy & {
            --root-max-width: 41rem;

            max-height: 51rem;
        }
    }
}

@include xxLarge {
    .root {
        --root-padding: var(--block-padding-end) 0;

        &.flushTop {
            padding-top: 0;
        }

        &.flushBottom {
            padding-bottom: 0;
        }
    }
}

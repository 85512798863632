@use 'sass:math';
@import 'styles/mixins';
@import 'styles/breakpoints';
@import 'styles/animations';
@import 'styles/colors';

.faqPage {
    width: 100%;
    margin-bottom: var(--block-padding-end);
}

.subNavPage {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 2rem;
}

.accordion {
    summary {
        color: var(--colors-orange);
    }
}

.subNav {
    position: sticky;
    z-index: 2;
    top: var(--block-padding-end);
    width: 100%;
}

.content {
    width: 100%;
    min-height: 50vh;
    padding: 0 var(--block-padding-side);
    margin: var(--block-padding-end) 0;
    color: var(--colors-blue);
    grid-column: 1 / -1;
}

.title {
    margin-bottom: 3rem;
    text-transform: uppercase;
}

.questionsEmpty {
    width: 100%;
    padding: 2.2rem 0;
    margin: 0;
    text-align: center;

    @include bottom-divider;
}

.sideNavContainer {
    position: sticky;
    z-index: 1;
    top: -0.1rem;
    width: calc(
        100% + var(--gridContainer-padding) + var(--gridContainer-padding)
    );
    margin-left: calc(-1 * var(--gridContainer-padding));
    grid-column: 1 / -1;
}

.sideNav {
    position: sticky;
    z-index: 1;
    top: -0.1rem;
    width: 100%;
    height: 5.6rem;
    padding-top: 0.1rem;
    color: var(--colors-blue);
    pointer-events: none;

    &.isOpen {
        pointer-events: auto;
    }
}

.sideNavAbsInner {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    transition: transform $slow $easeOutCubic;

    .isSticky & {
        transform: translateY(6rem);
        transition: transform $fast $easeInOutCubic;
    }

    .isSticky.isFlushTop & {
        transform: translateY(0);
        transition: transform $fast $easeInOutCubic;
    }
}

.sideNavAbsInnerBack {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
}

.sideNavHeader {
    position: relative;
    z-index: 1;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 5.6rem;
    flex-direction: column;
    justify-content: center;
    background-color: var(--colors-white);
    pointer-events: auto;
    text-align: center;
    transition: transform $slow $easeOutQuad;

    .isOpen & {
        transform: translateY(-5.6rem);
    }

    .label {
        padding: 0;
    }
}

.endCard {
    @include flex-center;

    flex-direction: column;
    padding: 2.4rem 4.2rem;
    border-radius: 2rem;
    margin-top: 4.8rem;
    background-color: var(--colors-white);

    > * {
        text-align: center;
    }

    > a {
        margin-top: 2rem;
    }
}

.openButton {
    position: absolute !important;
    right: 2.4rem !important;
}

.closeButton {
    position: absolute !important;
    z-index: 1 !important;
    right: 2.4rem !important;
    bottom: 1.9rem !important;
    transform: rotate(180deg) !important;
}

.sideNavInner {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    padding: 1.6rem 0;
    background-color: var(--colors-white);
    transform: translateY(-100%);
    transition: transform $fast $easeInQuad;

    .isOpen & {
        transform: translateY(0);
        transition: transform $fast $easeOutQuad;
    }
}

.sideNavGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.isActive {
        color: color('blueOcean');
    }
}

.sideLink {
    width: 100%;
    padding: 0.7rem 0;
    cursor: pointer;
    text-align: center;

    a {
        text-decoration: none;
    }
}

.label {
    position: relative;
    display: inline-block;
    padding: 1.6rem 0;
    text-transform: uppercase;

    a {
        display: block;
        width: 100%;
    }
}

.navCaret {
    position: absolute !important;
    top: 50% !important;
    right: 0 !important;
    display: none !important;
    transform: translateY(-50%) !important;
}

.loader {
    --loader-color: var(--colors-blue);

    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

@include medium {
    .endCard {
        padding: 5.8rem 10.9rem;

        > a {
            margin-top: 4rem;
        }
    }
}

@include large {
    .subNavPage {
        flex-direction: row;
        align-items: flex-start;

        > * {
            max-width: var(--block-max-width-plus-padding);
        }
    }

    .content {
        padding: var(--block-padding-end) 0;
        margin: 0;
        grid-column: 4 / span 9;
    }

    .sideNavContainer {
        position: static;
        width: calc(100% + var(--gridContainer-gap));
        margin-left: 0;
        grid-column: span 2;
    }

    .sideNav {
        min-width: 100% * math.div(2, 3);
        height: auto;
        pointer-events: auto;

        .faqPage--isDetail & {
            display: block;
            margin: 0;
        }
    }

    .sideNavHeader,
    .sideNavAbsInnerBack,
    .openButton,
    .closeButton {
        display: none !important;
    }

    .sideNavInner {
        position: relative;
        display: flex;
        height: auto;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 0;
        padding-top: var(--block-padding-end);
        background-color: transparent;
        transform: none;
    }

    .sideNavAbsInner {
        position: relative;
        height: auto;

        .isSticky & {
            transform: translateY(0);
        }

        .isSticky.isFlushTop & {
            transform: translateY(0);
        }
    }

    .sideNavGroup {
        position: relative;
        width: 100%;
        align-items: flex-start;

        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 0;
            content: '';

            @include bottom-divider;
        }

        &:first-child {
            &.isActive {
                &::before {
                    display: none;
                }
            }
        }
    }

    .sideLink {
        padding: 0 1.4rem 2rem;
        text-align: left;
    }

    .label {
        width: 100%;
        padding: 1.6rem 0 2.1rem;
        padding: 2rem 4rem 1.8rem 0;
    }

    .navCaret {
        display: flex !important;
    }
}
